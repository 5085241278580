import { ErrorInfo, ReactNode, useRef, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { Button } from '@components/Button';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { log, logBreadcrumb } from '@lib/utils';

/**
 * Our standard Error Boundary Fallback component
 * @param {error, resetErrorBoundary}
 * @returns JSX element
 */
function ErrorBoundaryFallback({
  error,
  resetErrorBoundary,
  location,
}: FallbackProps & { location: string }) {
  return (
    <div role="alert">
      <div className="mx-auto my-0.5 max-w-maxWidth border border-solid border-grey-light p-2">
        <Paragraph>
          Uh oh, we had a little problem displaying this section.
        </Paragraph>
        <Paragraph>
          Our Web Team has been notified and will be looking into it shortly.
        </Paragraph>
        <Paragraph>Error code:</Paragraph>
        <pre className="rounded bg-grey-light p-2.5 dark:bg-grey-dark">
          {error.message}
          <br />
          {error.name}
          <br />
          Thrown by: {location}
        </pre>
        <Paragraph>
          If this error persists, please get in contact with our Customer
          Service Team for help.
        </Paragraph>
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </div>
    </div>
  );
}

function handleBoundaryError(error: Error, info: ErrorInfo, location: string) {
  logBreadcrumb({
    message: 'Component stack for error',
    category: 'error',
    level: 'error',
    data: { componentStack: info.componentStack, location: location },
  });
  log({ error, location });
}

function Bomb({ username }) {
  if (username === 'bomb') {
    throw new Error('💥 KABOOM 💥');
  }
  return <>Hi {username}</>;
}

export function ErrorTest() {
  const [username, setUsername] = useState('');
  const usernameRef = useRef(null);

  return (
    <div>
      <label>
        {`Username (don't type "bomb"): `}
        <input
          placeholder={`type "bomb"`}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          ref={usernameRef}
        />
      </label>
      <div>
        <Bomb username={username} />
      </div>
    </div>
  );
}

export function BulkErrorBoundary({
  children,
  location,
}: {
  children: ReactNode;
  location: string;
}) {
  return (
    <ErrorBoundary
      fallbackRender={(props) => (
        <ErrorBoundaryFallback {...props} location={location} />
      )}
      onError={(error, info) => handleBoundaryError(error, info, location)}
    >
      {children}
    </ErrorBoundary>
  );
}
