import { ErrorMessage } from '@hookform/error-message';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import Image from 'next/image';
import React, { ReactElement, ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { IoIosSearch } from 'react-icons/io';

import { emailRegex, passwordRegex } from '@lib/regex';
import { mergeStyles } from '@lib/styles';
import { validatePassword } from '@lib/utils';
import { ALLOWED_FILE_TYPES } from 'pages/api/s3/fileUpload';

// This returns React Hook Form validation options
export const formatValidationWithErrors = (props, name) => {
  const validators = {};

  if (props.required) {
    validators['required'] = { value: true, message: name + ' is required' };
  }

  if (props.minLength) {
    validators['minLength'] = {
      value: parseInt(props.minLength),
      message: `Minimum length for ${name} is ${props.minLength} characters`,
    };
  }

  if (props.maxLength) {
    validators['maxLength'] = {
      value: parseInt(props.maxLength),
      message: `Maximum length for ${name} is ${props.maxLength} characters`,
    };
  }

  if (props.min !== null) {
    if (props.type === 'date') {
      const date = new Date();
      const daysToSubtract =
        typeof props.min === 'string' ? parseInt(props.min) : props.min;
      date.setDate(date.getDate() + daysToSubtract);
      const minDate = `${date.getFullYear()}-${(
        '0' +
        (date.getMonth() + 1)
      ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
      validators['min'] = {
        value: minDate,
        message: `Minimum date for ${name} is ${minDate}`,
      };
    } else {
      validators['min'] = {
        value: typeof props.min === 'string' ? parseInt(props.min) : props.min,
        message: `Minimum value for ${name} is ${props.min}`,
      };
    }
  }

  if (props.max !== null) {
    if (props.type === 'date') {
      const date = new Date();
      const daysToAdd =
        typeof props.max === 'string' ? parseInt(props.max) : props.max;
      date.setDate(date.getDate() + daysToAdd);
      const maxDate = `${date.getFullYear()}-${(
        '0' +
        (date.getMonth() + 1)
      ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
      validators['max'] = {
        value: maxDate,
        message: `Maximum date for ${name} is ${maxDate}`,
      };
    } else {
      validators['max'] = {
        value: typeof props.max === 'string' ? parseInt(props.max) : props.max,
        message: `Maximum value for ${name} is ${props.max}`,
      };
    }
  }

  if (props.pattern) {
    validators['pattern'] = {
      value: props.pattern,
      message: name + ' does not match the required format',
    };
  }

  return validators;
};

function FieldWrapper({ children }: { children: ReactNode }): ReactElement {
  return <div className="mb-4 flex flex-col gap-1">{children}</div>;
}

export function Label({
  label,
  required,
  fieldName,
  className,
}: {
  label: string | ReactNode;
  required?: boolean;
  fieldName: string;
  className?: string;
}): ReactElement {
  return (
    <label
      htmlFor={fieldName}
      className={mergeStyles(className ?? '', 'text-xs font-bold')}
    >
      {label}
      {required && (
        <>
          <span className="ml-1 text-xs uppercase text-red">*</span>
          <VisuallyHidden> required</VisuallyHidden>
        </>
      )}
    </label>
  );
}

function Description({ description }: { description: string }): ReactElement {
  return <span className="description">{description}</span>;
}

export function ErrorText({ message }: { message: string }): ReactElement {
  return (
    <span className="text-red before:inline before:content-['⚠_']">
      {message}
    </span>
  );
}

export function Input({
  name,
  label,
  inlineErrors = true,
  ...props
}: { name: string; label: string | ReactNode; inlineErrors?: boolean } & Record<
  string,
  any
>): ReactElement {
  if (props.type === 'radio') {
    return (
      <RadioInput
        name={name}
        label={label}
        inlineErrors={inlineErrors}
        {...props}
      />
    );
  } else if (props.type === 'checkbox') {
    return (
      <CheckboxInput
        name={name}
        label={label}
        inlineErrors={inlineErrors}
        {...props}
      />
    );
  } else if (props.type === 'date') {
    return (
      <DateInput
        name={name}
        label={label}
        inlineErrors={inlineErrors}
        {...props}
      />
    );
  } else if (props.type === 'number') {
    return (
      <NumberInput
        name={name}
        label={label}
        inlineErrors={inlineErrors}
        {...props}
      />
    );
  } else if (props.type === 'email') {
    return (
      <EmailInput
        name={name}
        label={label}
        inlineErrors={inlineErrors}
        {...props}
      />
    );
  } else if (props.type === 'password') {
    return (
      <PasswordInput
        name={name}
        label={label}
        inlineErrors={inlineErrors}
        {...props}
      />
    );
  } else if (props.type === 'search') {
    return (
      <SearchInput
        name={name}
        label={label}
        inlineErrors={inlineErrors}
        {...props}
      />
    );
  } else {
    return (
      <TextInput
        name={name}
        label={label}
        inlineErrors={inlineErrors}
        {...props}
      />
    );
  }
}

export function Textarea({
  name,
  label,
  inlineErrors = true,
  ...props
}: { name: string; label: string; inlineErrors?: boolean } & Record<
  string,
  any
>): ReactElement {
  const { formState, register } = useFormContext();

  return (
    <FieldWrapper>
      <Label label={label} fieldName={name} required={props.required} />
      <textarea
        {...register(name, {
          ...formatValidationWithErrors(props, label),
          validate: props.validate ? props.validate : null,
        })}
        {...props}
        name={name}
        id={name}
        rows={props.rows ?? 3}
        className="resize-y border border-solid border-grey-mid bg-white text-black"
      />
      {inlineErrors && formState.errors[name] ? (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorText message={message} />}
        />
      ) : null}
    </FieldWrapper>
  );
}

export function Select({
  name,
  label,
  className = '',
  inlineErrors = true,
  ...props
}: {
  name: string;
  label: string;
  className?: string;
  inlineErrors?: boolean;
} & Record<string, any>): ReactElement {
  const { formState, register } = useFormContext();

  if (!props.options || !Array.isArray(props.options)) {
    return <></>;
  }

  return (
    <FieldWrapper>
      <div
        className={mergeStyles(
          className,
          "relative w-fit after:pointer-events-none after:absolute after:right-0 after:top-1/2 after:h-5 after:w-5 after:translate-y-0 after:bg-[url('data:image/svg+xml,%0A%3Csvg%20fill%3D%22%23000000%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7.41%207.84L12%2012.42l4.59-4.58L18%209.25l-6%206-6-6z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0-.75h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E')] after:bg-contain after:bg-center after:bg-no-repeat after:opacity-50 dark:after:bg-[url('data:image/svg+xml,%0A%3Csvg%20fill%3D%22%23ffffff%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7.41%207.84L12%2012.42l4.59-4.58L18%209.25l-6%206-6-6z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0-.75h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E')]"
        )}
      >
        <Label
          label={label}
          fieldName={name}
          required={props.required}
          className={props.labelclasses}
        />
        <select
          {...register(name, {
            ...formatValidationWithErrors(props, label),
            validate: props.validate ? props.validate : null,
          })}
          {...props}
          name={name}
          aria-label={name}
          className="font-inherit cursor-inherit m-0 h-10 w-full min-w-[150px] 
          appearance-none rounded-none border border-solid border-grey-mid bg-grey-light p-2.5
          leading-inherit text-black dark:border-grey-dark dark:bg-grey-dark dark:text-white"
        >
          <option value="">Please select</option>
          {props.options
            .filter((o) => !(o === null)) // this is for dodgy data (when variants removed, usually affects staging)
            .map((o) => (
              <option
                value={o.value}
                key={`option-${o.value}`}
                disabled={o.disabled ?? false}
                className="dark:disabled:text-grey-darkest"
              >
                {o.label}
              </option>
            ))}
        </select>
        {inlineErrors && formState.errors[name] ? (
          <ErrorMessage
            errors={formState.errors}
            name={name}
            render={({ message }) => <ErrorText message={message} />}
          />
        ) : null}
      </div>
    </FieldWrapper>
  );
}

export function ImageInput({
  name,
  label,
  inlineErrors = true,
  ...props
}: { name: string; label: string | ReactNode; inlineErrors?: boolean } & Record<
  string,
  any
>): ReactElement {
  const { formState, register } = useFormContext();

  return (
    <FieldWrapper>
      {label && (
        <Label label={label} required={props.required} fieldName={name} />
      )}
      <input
        {...props}
        {...register(name, { ...formatValidationWithErrors(props, label) })}
        name={name}
        id={name}
        type="file"
        multiple={false}
        accept={ALLOWED_FILE_TYPES.join(',')}
        onChange={props.onChange}
      />
      {props.previewUrl && (
        <Image
          src={props.previewUrl}
          alt="Preview of the image you have selected"
          width={75}
          height={75}
        />
      )}
      {props.description && <Description description={props.description} />}
      {inlineErrors && formState.errors[name] ? (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorText message={message} />}
        />
      ) : null}
    </FieldWrapper>
  );
}

// The below are all used by the Input component

function DateInput({
  name,
  label,
  inlineErrors = true,
  ...props
}: { name: string; label: string | ReactNode; inlineErrors?: boolean } & Record<
  string,
  any
>): ReactElement {
  const { formState, register } = useFormContext();

  return (
    <FieldWrapper>
      {label && (
        <Label label={label} required={props.required} fieldName={name} />
      )}
      <input
        {...register(name, {
          ...formatValidationWithErrors(props, label),
          validate: props.validate ? props.validate : null,
        })}
        type="date"
        className={`h-10 border border-solid border-grey-mid bg-white 
          p-2.5 text-black placeholder:text-grey-dark data-[error=true]:border-red`}
      />
      {props.description && <Description description={props.description} />}
      {inlineErrors && formState.errors[name] ? (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorText message={message} />}
        />
      ) : null}
    </FieldWrapper>
  );
}

function NumberInput({
  name,
  label,
  inlineErrors = true,
  ...props
}: { name: string; label: string | ReactNode; inlineErrors?: boolean } & Record<
  string,
  any
>): ReactElement {
  const { formState, register } = useFormContext();

  return (
    <FieldWrapper>
      {label && (
        <Label label={label} required={props.required} fieldName={name} />
      )}
      <input
        {...props}
        className={mergeStyles(
          props.className ?? '',
          `h-10 border border-solid border-grey-mid bg-white 
        p-2.5 text-black placeholder:text-grey-dark`
        )}
        {...register(name, {
          ...formatValidationWithErrors(props, label),
          validate: props.validate ? props.validate : null,
        })}
      />
      {props.description && <Description description={props.description} />}
      {inlineErrors && formState.errors[name] ? (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorText message={message} />}
        />
      ) : null}
    </FieldWrapper>
  );
}

function CheckboxInput({
  name,
  label,
  inlineErrors = true,
  ...props
}: { name: string; label: string | ReactNode; inlineErrors?: boolean } & Record<
  string,
  any
>): ReactElement {
  const { formState, register } = useFormContext();

  if (!props.options) {
    return (
      <div className="mb-4">
        <div className="mb-1 flex gap-1 self-start">
          {label && (
            <Label
              label={label}
              required={props.required}
              fieldName={name}
              className="order-2"
            />
          )}
          <input
            {...register(name, {
              ...formatValidationWithErrors(props, label),
              validate: props.validate ? props.validate : null,
            })}
            {...props}
            id={name}
            name={name}
            value={name}
            className="order-1 w-fit"
          />
        </div>
        {props.description && <Description description={props.description} />}
        {inlineErrors && formState.errors[name] ? (
          <ErrorMessage
            errors={formState.errors}
            name={name}
            render={({ message }) => <ErrorText message={message} />}
          />
        ) : null}
      </div>
    );
  }

  return (
    <FieldWrapper>
      <Label label={label} fieldName={name} required={props.required} />
      {props.options.map((o) => (
        <div className="flex items-center gap-2.5" key={o.value}>
          <input
            {...register(name, {
              ...formatValidationWithErrors(props, label),
              validate: props.validate ? props.validate : null,
            })}
            {...props}
            id={o.value}
            name={name}
            value={o.value}
          />
          <label htmlFor={o.value} className="checkbox">
            {o.label}
          </label>
        </div>
      ))}
      {inlineErrors && formState.errors[name] ? (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorText message={message} />}
        />
      ) : null}
    </FieldWrapper>
  );
}

function RadioInput({
  name,
  label,
  inlineErrors = true,
  ...props
}: { name: string; label: string | ReactNode; inlineErrors?: boolean } & Record<
  string,
  any
>): ReactElement {
  const { formState, register } = useFormContext();

  if (!props.options) {
    return <></>;
  }
  return (
    <FieldWrapper>
      {label && (
        <Label label={label} required={props.required} fieldName={name} />
      )}
      {props.options.map((o) => (
        <div key={o.value ?? o} className="flex items-center gap-2">
          <input
            {...register(name, {
              ...formatValidationWithErrors(props, label),
              validate: props.validate ? props.validate : null,
            })}
            {...props}
            id={o.value ?? o}
            name={name}
            value={o.value ? o.value : (o.label ?? o)}
            className="h-[1.5rem] border border-solid border-grey-mid bg-white 
            p-2.5 text-black placeholder:text-grey-dark"
          />
          <label htmlFor={o.value ?? o} className="radio">
            {o.label ?? o}
          </label>
        </div>
      ))}
      {inlineErrors && formState.errors[name] ? (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorText message={message} />}
        />
      ) : null}
    </FieldWrapper>
  );
}

function TextInput({
  name,
  label,
  inlineErrors = true,
  ...props
}: { name: string; label: string | ReactNode; inlineErrors?: boolean } & Record<
  string,
  any
>): ReactElement {
  const { formState, register } = useFormContext();
  return (
    <FieldWrapper>
      {label && (
        <Label label={label} required={props.required} fieldName={name} />
      )}
      <input
        {...props}
        className={mergeStyles(
          props.className ?? '',
          `h-10 min-w-[200px] border border-solid border-grey-mid 
        bg-white p-2.5 text-black placeholder:text-grey`
        )}
        {...register(name, {
          ...formatValidationWithErrors(props, label),
          validate: props.validate ? props.validate : null,
        })}
      />
      {props.description && <Description description={props.description} />}
      {inlineErrors && formState.errors[name] ? (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorText message={message} />}
        />
      ) : null}
    </FieldWrapper>
  );
}

function SearchInput({
  name,
  label,
  inlineErrors = true,
  ...props
}: { name: string; label: string | ReactNode; inlineErrors?: boolean } & Record<
  string,
  any
>): ReactElement {
  const { formState, register } = useFormContext();
  return (
    <div className="flex flex-col gap-1">
      <VisuallyHidden>
        <Label label="Search term" required={props.required} fieldName={name} />
      </VisuallyHidden>

      <div className="flex items-center border border-solid border-grey-mid bg-white text-grey-dark placeholder:text-grey-dark">
        {!props.placeholder && <IoIosSearch size="1.5rem" className="pl-1" />}
        <input
          {...props}
          className={mergeStyles(
            props.className,
            'h-10 w-[480px] p-2.5 text-black placeholder:italic'
          )}
          placeholder={props.placeholder ?? 'Search Bulk Nutrients'}
          {...register(name, {
            ...formatValidationWithErrors(props, label),
            validate: props.validate ? props.validate : null,
          })}
        />
      </div>
      {props.description && <Description description={props.description} />}
      {inlineErrors && formState.errors[name] ? (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorText message={message} />}
        />
      ) : null}
    </div>
  );
}

function EmailInput({
  name,
  label,
  inlineErrors = true,
  ...props
}: { name: string; label: string | ReactNode; inlineErrors?: boolean } & Record<
  string,
  any
>): ReactElement {
  const { formState, register } = useFormContext();
  const standardValidation = (value) => {
    if (!emailRegex.test(value)) {
      const errorText = 'Invalid email address';
      return errorText;
    }
  };

  return (
    <FieldWrapper>
      {label && (
        <Label label={label} required={props.required} fieldName={name} />
      )}
      <input
        {...props}
        autoComplete="true"
        className={mergeStyles(
          props.className ?? '',
          `h-10 min-w-[200px] border border-solid border-grey-mid 
        bg-white p-2.5 text-black placeholder:text-grey-dark`
        )}
        {...register(name, {
          ...formatValidationWithErrors(props, label),
          validate: props.validate ? props.validate : standardValidation,
        })}
      />
      {props.description && <Description description={props.description} />}
      {inlineErrors && formState.errors[name] ? (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorText message={message} />}
        />
      ) : null}
    </FieldWrapper>
  );
}

function PasswordInput({
  name,
  label,
  inlineErrors = true,
  validate,
  ...props
}: {
  name: string;
  label: string | ReactNode;
  inlineErrors?: boolean;
  validate?: (value: string) => string | undefined;
} & Record<string, any>): ReactElement {
  const { formState, register } = useFormContext();

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const passwordValidation = (value) => {
    if (!passwordRegex.test(value)) {
      const errorText = validatePassword(value);
      return errorText;
    }
  };

  const inputProps = {
    ...props,
    type: passwordVisibility ? 'text' : 'password',
  };

  return (
    <FieldWrapper>
      {label && (
        <Label label={label} required={props.required} fieldName={name} />
      )}
      <div
        className={mergeStyles(
          props.className ?? '',
          'flex flex-row items-center'
        )}
      >
        <input
          {...inputProps}
          className="h-10 w-full border border-solid border-grey-mid bg-white p-2.5 text-black placeholder:text-grey-dark"
          {...register(name, {
            ...formatValidationWithErrors(props, label),
            validate: validate ? validate : passwordValidation,
          })}
        />
        {!passwordVisibility ? (
          <FiEye
            className="w-10 cursor-pointer text-grey-dark dark:text-grey-mid"
            size={24}
            onClick={() => setPasswordVisibility(true)}
          />
        ) : (
          <FiEyeOff
            className="w-10 cursor-pointer text-grey-dark dark:text-grey-mid"
            size={24}
            onClick={() => setPasswordVisibility(false)}
          />
        )}
      </div>
      {props.description && <Description description={props.description} />}
      {inlineErrors && formState.errors[name] ? (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorText message={message} />}
        />
      ) : null}
    </FieldWrapper>
  );
}
