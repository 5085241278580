import { Loader } from '@googlemaps/js-api-loader';
import { ErrorMessage } from '@hookform/error-message';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ErrorText, Label } from './FormComponents';

function AutocompleteInput({
  ausOnly,
  // reset,
  validAddress,
}: {
  ausOnly: boolean;
  validAddress: boolean;
}) {
  const ref = useRef<HTMLInputElement>(null);
  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);

  const [placeSelected, setPlaceSelected] = useState(false);

  const { setValue, formState, trigger } = useFormContext();

  const validateGoogleSelection = (value: string) => {
    if (value && !validAddress && !placeSelected) {
      return 'Please select an address from the dropdown options';
    }
  };

  // Load in Autocomplete
  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
      version: 'weekly',
      libraries: ['places'],
    });

    const options = {
      componentRestrictions: { country: ausOnly ? ['au'] : ['au', 'nz'] },
      fields: ['address_components'],
      strictBounds: false,
      types: ['address'],
    };

    loader
      .load()
      .then((google) => {
        if (ref.current) {
          autocomplete.current = new google.maps.places.Autocomplete(
            ref.current,
            options
          );

          autocomplete.current.addListener('place_changed', () => {
            const place = autocomplete.current?.getPlace();
            if (!place) return;

            const components =
              place.address_components as google.maps.GeocoderAddressComponent[];
            const unit = components.filter(
              (c) => c.types[0] === 'subpremise'
            )[0]?.long_name;
            const streetNumber = components.filter(
              (c) => c.types[0] === 'street_number'
            )[0]?.long_name;
            const street = components.filter((c) => c.types[0] === 'route')[0]
              ?.long_name;
            const postCode = components.filter(
              (c) => c.types[0] === 'postal_code'
            )[0]?.long_name;
            const suburb = components.filter(
              (c) => c.types[0] === 'locality'
            )[0]?.long_name;
            const state = components.filter(
              (c) => c.types[0] === 'administrative_area_level_1'
            )[0]?.long_name;
            const country = components.filter(
              (c) => c.types[0] === 'country'
            )[0]?.short_name;

            const address1 = `${unit ? unit + '/' : ''}${streetNumber ? streetNumber : ''
              } ${street ? street : ''}`.trim();

            if (address1) setValue('address1', address1);
            if (postCode) setValue('postcode', postCode);
            if (suburb) setValue('suburb', suburb);
            if (state) setValue('state', state);
            if (country) setValue('country', country);

            const formattedInput = [address1, suburb, state, postCode].join(
              ', '
            );

            // set placeSelected so validation knows dropdown has been selected
            setPlaceSelected(true);
            // set value of input to the selected value so it reflects the correct address
            setValue('addressAutocomplete', formattedInput);
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="flex flex-col gap-1">
      <Controller
        name="addressAutocomplete"
        rules={{
          required: validAddress
            ? false
            : { value: true, message: 'This field is required' },
          validate: validateGoogleSelection,
        }}
        render={({ field }) => (
          <input
            ref={(e) => {
              field.ref(e);
              ref.current = e;
            }}
            placeholder="Start typing your address and select from the dropdown to proceed"
            className="h-10 min-w-[200px] border border-solid border-grey-mid bg-white p-2.5 text-black placeholder:text-grey-dark"
            value={field.value || ''}
            onChange={field.onChange}
            onBlur={() => {
              field.onBlur();
              setTimeout(() => trigger('addressAutocomplete'), 200);
            }}
          />
        )}
      />
      {formState.errors['addressAutocomplete'] ? (
        <ErrorMessage
          errors={formState.errors}
          name="addressAutocomplete"
          render={({ message }) => <ErrorText message={message} />}
        />
      ) : null}
    </div>
  );
}

export function GoogleAutocomplete({
  ausOnly,
  validAddress,
}: {
  ausOnly: boolean;
  validAddress: boolean;
}) {
  return (
    <div className="flex flex-col gap-1">
      <Label label="Address" fieldName="addressAutocomplete" required />
      <AutocompleteInput ausOnly={ausOnly} validAddress={validAddress} />
    </div>
  );
}
